<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'App',
  components: {
  },

  mounted() {
  },
}
</script>

<style type="text/css">
#app {
  height: 100%;
}

body,html {
  padding: 0;
  margin: 0;
  height: 100%;
}

a {
  color: black;
  text-decoration: none;
}

section {
  line-height: 30px;
}

li {
  line-height: 30px;
}

.nav {
  z-index: 999;
  position: fixed;
  top: 0;
  width: 100%;
  height: 90px;
  background-color: #141414;
}

.nav-mobile {
  z-index: 999;
  position: fixed;
  top: 0;
  width: 100%;
  height: 56px;
  background-color: #141414;
}

.logo-image {
  position: relative;
  left: 100px;
  top: 24px;
  width: 16.3%;
  height: auto;
}

.logo-image-mobile {
  position: relative;
  left: 16px;
  top: 18px;
  width: 41.6%;
}

.nav-image {
  float: right;
  position: relative;
  top: 34px;
  width: 4%;
  height: auto;
  margin: 0 64px 0 0;
}

.nav-image-mobile {
  float: right;
  position: relative;
  top: 24px;
  width: 8%;
  margin: 0 16px 0 0;
}

.nav-image:hover {
  cursor: pointer;
}

.nav-image-mobile:hover {
  cursor: pointer;
}
</style>
