import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
const app = createApp(App)

import 'element-plus/dist/index.css'
import { ElBacktop, ElCol, ElContainer, ElHeader, ElImage, ElMain, ElRow } from 'element-plus'

app.use(ElRow)
app.use(ElImage)
app.use(ElCol)
app.use(ElBacktop)
app.use(ElContainer)
app.use(ElHeader)
app.use(ElMain)

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title
  }

  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
  window.pageYOffset = 0;

  next()
})
app.use(router);

app.mount('#app')
