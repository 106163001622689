import { createRouter, createWebHashHistory } from 'vue-router';
// import Help from '../views/help'
import Terms from '../views/terms'
import Privacy from '../views/privacy'
import Home from '../views'

import HelpEn from '../views/help/en'
import HelpCn from '../views/help/cn'
import HelpTc from '../views/help/tc'

const title = '移趣应用产品接入';

const routes = [
  {
    path: '/',
    component: Home,
    meta: {
      title: title
    }
  },
  // {
  //   path: '/help',
  //   component: Help,
  //   meta: {
  //     title: title
  //   }
  // },
  {
    path: '/terms',
    component: Terms,
    meta: {
      title: title
    }
  },
  {
    path: '/privacy',
    component: Privacy,
    meta: {
      title: title
    }
  },
  {
    path: '/watertracker-help-cn',
    component: HelpCn,
    meta: {
      title: title
    }
  },
  {
    path: '/watertracker-help-en',
    component: HelpEn,
    meta: {
      title: title
    }
  },
  {
    path: '/watertracker-help-tc',
    component: HelpTc,
    meta: {
      title: title
    }
  }
];

const router = createRouter({
  routes, history: createWebHashHistory()
});

export default router;